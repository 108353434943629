define("discourse/plugins/discourse-characters-required/discourse/components/characters-required", ["exports", "@ember/component", "@ember-decorators/component", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showRequired}}
    {{i18n "characters_required" count=this.composer.missingReplyCharacters}}
  {{/if}}
  */
  {
    "id": "gXeTXa6u",
    "block": "[[[41,[30,0,[\"showRequired\"]],[[[1,\"  \"],[1,[28,[35,1],[\"characters_required\"],[[\"count\"],[[30,0,[\"composer\",\"missingReplyCharacters\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-characters-required/discourse/components/characters-required.hbs",
    "isStrictMode": false
  });
  const CharactersRequired = dt7948.c(class CharactersRequired extends _component.default {
    showRequired(missing) {
      return missing > 0;
    }
    static #_ = (() => dt7948.n(this.prototype, "showRequired", [(0, _decorators.default)("composer.missingReplyCharacters")]))();
  }, [(0, _component2.classNames)("characters-required")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CharactersRequired);
});